<template>
  <v-card width="350">
    <v-col align="center">
      <h3 class="mt-5 mb-2">Паспорт почвы</h3>

      <v-row dense>
        <v-col cols="12" sm="9">
          <v-card-text class="text-start"
          >Идентификационный номер</v-card-text
          >
        </v-col>
        <v-col cols="12" sm="3">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="12">
          <v-select
            :items="passport_purposeland"
            v-model="purpose_land_id"
            item-text="name"
            item-value="id"
            placeholder="Назначение"
            outlined
            dense
            hide-details
            class="mb-1"
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Дата обследования</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_examination"
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-date-picker min="1960-01-01" max="2025-01-01" v-model="date_of_examination" scrollable></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Год проведения последнего обследования</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-menu v-model="plantingDateMenu2">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="year_of_last_examination"
                v-bind="attrs"
                v-on="on"
                hide-details
                clearable
              ></v-text-field>
            </template>
            <v-date-picker-years min="1960" max="2024" v-model="year_of_last_examination" scrollable></v-date-picker-years>
          </v-menu>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Мышьяк Валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="arsenic"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Свинец валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="lead"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Кадмий валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="cadmium"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Ртуть валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="mercury"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Хром валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="chrome"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Медь валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="copper"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Цинк валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="zinc"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Никель валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="nickel"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Марганец валовый</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="manganese"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Алюминий</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="aluminum"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Железо валовое</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="iron"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">рН</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="ph"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Нефтепродукты</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="text-end"
            v-model="petroleum_products"
            type="number"
            step="1"
            min="0"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Адм. район</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ district_name }}</v-card-text>
        </v-col>
      </v-row>

      <div class="mt-2">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">
              <v-btn
                type="submit"
                class="font-weight-bold"
                @click="saveClick"
                :disabled="
                      $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
              >Сохранить</v-btn
              >
            </v-card-text>
          </v-col>
          <v-col v-if="isDraw" cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
              type="button"
              class="font-weight-bold"
              @click="clearData"
              :disabled="
                    $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
            >Очистить</v-btn
            ></v-card-text
            >
          </v-col>
          <v-col v-else cols="12" sm="6" justify="end">
            <v-card-text class="text-start"
            ><v-btn
              type="button"
              class="font-weight-bold"
              @click="deleteClick"
              :disabled="
                    $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
            >Удалить</v-btn
            ></v-card-text
            >
          </v-col>
        </v-row>
      </div>

    </v-col>
  </v-card>
</template>

<script>

import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "SoilPassport",

  props: {
    saveSoil: Function,
    deleteSoil: Function,
    properties: Object,
    isDraw: Boolean,
  },

  data() {
    return {
      plantingDateMenu: false,
      plantingDateMenu2: false,

      passport_purposeland: [],

      image: undefined,
      imageUrl: "",
      imgRules: [
        value =>
          !value ||
          value.size < 100000000 ||
          "Размер фото не должен превышать 100 МБ!"
      ],

      purpose_land_id: null,
      date_of_examination: null,
      year_of_last_examination: null,
      arsenic: null,
      lead: null,
      cadmium: null,
      mercury: null,
      chrome: null,
      copper: null,
      zinc: null,
      nickel: null,
      manganese: null,
      aluminum: null,
      iron: null,
      ph: null,
      petroleum_products: null,
      district_id: null,
      note: null,

      latitude: null,
      longitude: null,

      district_name: null,
    };
  },

  methods: {
    saveClick() {
      const data = {
        purpose_land_id: this.purpose_land_id,
        date_of_examination: this.date_of_examination,
        year_of_last_examination: this.year_of_last_examination,
        arsenic: this.arsenic,
        lead: this.lead,
        cadmium: this.cadmium,
        mercury: this.mercury,
        chrome: this.chrome,
        copper: this.copper,
        zinc: this.zinc,
        nickel: this.nickel,
        manganese: this.manganese,
        aluminum: this.aluminum,
        iron: this.iron,
        ph: this.ph,
        petroleum_products: this.petroleum_products,
        note: this.note
      };

      this.saveSoil(data);
    },
    deleteClick() {
      this.deleteSoil();
    },
    clearData() {
      this.purpose_land_id = null;
      this.date_of_examination = null;
      this.year_of_last_examination = null;
      this.arsenic = null;
      this.lead = null;
      this.cadmium = null;
      this.mercury = null;
      this.chrome = null;
      this.copper = null;
      this.zinc = null;
      this.nickel = null;
      this.manganese = null;
      this.aluminum = null;
      this.iron = null;
      this.ph = null;
      this.petroleum_products = null;
      this.district_id = null;
      this.note = null;
    },

    uploadImage(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    clearImg() {
      if (
        confirm("Желаете удалить фото у объекта " + this.properties.id + "?")
      ) {
        this.removeImg(this.properties.id);
        this.imageUrl = "";
      } else {
        this.uploadImage();
      }
    }
  },

  async mounted() {
    let response = await Vue.axios.get(`${URL_API}purposeland`);
    this.passport_purposeland = response.data;

    if (this.properties?.id) {
      response = await Vue.axios.get(
        `${URL_API}soil/view/${this.properties.id}`
      );
      const data = response.data;
      console.log("данные почвы", data);
      this.purpose_land_id = data.purpose_land_id;
      this.date_of_examination = data.date_of_examination;
      this.year_of_last_examination = data.year_of_last_examination;
      this.arsenic = data.arsenic;
      this.lead = data.lead;
      this.cadmium = data.cadmium;
      this.mercury = data.mercury;
      this.chrome = data.chrome;
      this.copper = data.copper;
      this.zinc = data.zinc;
      this.nickel = data.nickel;
      this.manganese = data.manganese;
      this.aluminum = data.aluminum;
      this.iron = data.iron;
      this.ph = data.ph;
      this.petroleum_products = data.petroleum_products;
      this.district_id = data.district_id;
      this.note = data.note;

      this.latitude = data.geometry?.coordinates[0];
      this.longitude = data.geometry?.coordinates[1];

      if (this.district_id) {
        this.district_name = data.district.name;
      }
    }
  },

};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>